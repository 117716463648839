<div class="page-wrapper auth">
  <div class="page-inner bg-brand-gradient">
    <div class="page-content-wrapper bg-transparent m-0">
      <div class="height-10 w-100 shadow-lg px-4 bg-brand-gradient">
        <div class="d-flex align-items-center container p-0">
          <div
            class="page-logo width-mobile-auto m-0 align-items-center justify-content-center p-0 bg-transparent bg-img-none shadow-0 height-9 border-0">
            <a href="javascript:void(0)" class="page-logo-link press-scale-down d-flex align-items-center">
              <img src="assets/img/logo.png" alt="SmartAdmin WebApp" aria-roledescription="logo">
              <span class="page-logo-text mr-1" transloco="app.name"></span>
            </a>
          </div>
          <!-- Phần menu phụ -->
          <!-- end -->
        </div>
      </div>
      <div class="flex-1"
        style="background: url(assets/img/svg/pattern-1.svg) no-repeat center bottom fixed; background-size: cover;">
        <div class="container py-4 py-lg-5 my-lg-5 px-4 px-sm-0">

          <!-- Phần content  -->
          <router-outlet></router-outlet>

          <!-- Phần footer -->
          <!-- <div class="position-absolute pos-bottom pos-left pos-right p-3 text-center text-white">
            2020 © SmartAdmin by&nbsp;<a href='https://www.gotbootstrap.com' class='text-white opacity-40 fw-500'
              title='gotbootstrap.com' target='_blank'>gotbootstrap.com</a>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>

<!-- BEGIN Color profile -->
<!-- this area is hidden and will not be seen on screens or screen readers -->
<!-- we use this only for CSS color refernce for JS stuff -->
<p id="js-color-profile" class="d-none">
  <span class="color-primary-50"></span>
  <span class="color-primary-100"></span>
  <span class="color-primary-200"></span>
  <span class="color-primary-300"></span>
  <span class="color-primary-400"></span>
  <span class="color-primary-500"></span>
  <span class="color-primary-600"></span>
  <span class="color-primary-700"></span>
  <span class="color-primary-800"></span>
  <span class="color-primary-900"></span>
  <span class="color-info-50"></span>
  <span class="color-info-100"></span>
  <span class="color-info-200"></span>
  <span class="color-info-300"></span>
  <span class="color-info-400"></span>
  <span class="color-info-500"></span>
  <span class="color-info-600"></span>
  <span class="color-info-700"></span>
  <span class="color-info-800"></span>
  <span class="color-info-900"></span>
  <span class="color-danger-50"></span>
  <span class="color-danger-100"></span>
  <span class="color-danger-200"></span>
  <span class="color-danger-300"></span>
  <span class="color-danger-400"></span>
  <span class="color-danger-500"></span>
  <span class="color-danger-600"></span>
  <span class="color-danger-700"></span>
  <span class="color-danger-800"></span>
  <span class="color-danger-900"></span>
  <span class="color-warning-50"></span>
  <span class="color-warning-100"></span>
  <span class="color-warning-200"></span>
  <span class="color-warning-300"></span>
  <span class="color-warning-400"></span>
  <span class="color-warning-500"></span>
  <span class="color-warning-600"></span>
  <span class="color-warning-700"></span>
  <span class="color-warning-800"></span>
  <span class="color-warning-900"></span>
  <span class="color-success-50"></span>
  <span class="color-success-100"></span>
  <span class="color-success-200"></span>
  <span class="color-success-300"></span>
  <span class="color-success-400"></span>
  <span class="color-success-500"></span>
  <span class="color-success-600"></span>
  <span class="color-success-700"></span>
  <span class="color-success-800"></span>
  <span class="color-success-900"></span>
  <span class="color-fusion-50"></span>
  <span class="color-fusion-100"></span>
  <span class="color-fusion-200"></span>
  <span class="color-fusion-300"></span>
  <span class="color-fusion-400"></span>
  <span class="color-fusion-500"></span>
  <span class="color-fusion-600"></span>
  <span class="color-fusion-700"></span>
  <span class="color-fusion-800"></span>
  <span class="color-fusion-900"></span>
</p>
<!-- END Color profile -->
